var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-customer"},[_c('h3',{staticClass:"text-primary main-title"},[_vm._v("Add Customer")]),_c('ValidationObserver',{ref:"addCustomer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"id":"addCustomer"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CCard',{staticClass:"mt-2"},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"customer_type"}},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Select the type of Customer")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"customer_type_id","value":_vm.customer.customer_type_id,"options":_vm.options && _vm.options['customer_type']
                          ? _vm.options['customer_type']
                          : [],"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1)],1),_c('CRow',[(_vm.isHospital || _vm.isEducationalInstitute)?_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Is this Parent Organisation")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('RadioButton',{attrs:{"name":"is_parent_admin","value":_vm.customer.is_parent_admin,"options":_vm.options && _vm.options['is_parent_admin']
                          ? _vm.options['is_parent_admin']
                          : [],"error":errors[0]},on:{"change":_vm.handleRadioButton}})]}}],null,true)})],1)])],1):_vm._e(),(_vm.isHospital || _vm.isEducationalInstitute)?_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Organisation Type")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"org_type_id","value":_vm.customer.org_type_id,"options":_vm.options && _vm.options['organization_type']
                          ? _vm.options['organization_type']
                          : [],"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1):_vm._e()],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"institution_name"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Institution Name")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"name","value":_vm.customer.name,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"address"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Address")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"address","value":_vm.customer.address,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"country"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Country")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{ref:"country_ids",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"country_ids","value":_vm.customer.country_ids,"options":_vm.options && _vm.options['country']
                          ? _vm.options['country']
                          : [],"multiple":!_vm.isParentAdmin,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"required col-lg-12 col-md-12",attrs:{"name":"location"}},[_vm._v("City")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{ref:"location_ids",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"location_ids","value":_vm.customer.location_ids,"options":_vm.options && _vm.options['location']
                          ? _vm.options['location']
                          : [],"multiple":!_vm.isParentAdmin,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"district"}},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Districts")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('Select',{attrs:{"name":"district_ids","value":_vm.customer.district_ids,"options":_vm.options && _vm.options['districts']
                        ? _vm.options['districts']
                        : [],"multiple":!_vm.isParentAdmin,"clearable":false},on:{"input":_vm.handleChangeSelect}})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"license_authority"}},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("License Authority")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('Select',{attrs:{"name":"issue_auth_ids","value":_vm.customer.issue_auth_ids,"options":_vm.options && _vm.options['license_authority']
                        ? _vm.options['license_authority']
                        : [],"multiple":true,"clearable":false},on:{"input":_vm.handleChangeMultiSelectAsArray}})],1)])],1)],1)],1)],1),_c('CCard',[_c('CCardBody',[_c('h5',{staticClass:"pb-2 text-primary"},[_vm._v("Main Contact")]),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"first_name"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("First Name")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"first_name","value":_vm.customer.first_name,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"last_name"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Last Name")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"last_name","value":_vm.customer.last_name,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"phone"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Phone")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{ref:"phone",attrs:{"rules":{
                      required: true,
                      phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PhoneInput',{ref:"phoneNo",attrs:{"name":"phone","value":_vm.customer.phone,"options":_vm.options && _vm.options['dialingCode']
                          ? _vm.options['dialingCode']
                          : [],"error":errors[0]},on:{"change":_vm.handleInput}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"email"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Email")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"email","value":_vm.customer.email,"error":errors[0],"autocomplete":"new-email"},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"password"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Password")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"name":"password","rules":{
                      required: true,
                      password_length: 8,
                      password_strength:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PasswordInput',{attrs:{"name":"password","value":_vm.customer.password,"error":errors[0],"autocomplete":"new-password"},on:{"input":_vm.handleInput}}),_c('small',{staticClass:"hint"},[_vm._v(" Password format: 8 characters - containing upper & lower case letters, numbers and a special character. No words. ")])]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3",attrs:{"name":"confirm_password"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Confirm Password")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PasswordInput',{attrs:{"name":"confirmPassword","value":_vm.confirmPassword,"error":errors[0]},on:{"input":_vm.handleConfirmPassword}})]}}],null,true)})],1)])],1)],1)],1)],1),_c('div',{staticClass:"text-center mx-0 mt-2 pt-4 action-area"},[_c('CButton',{staticClass:"px-5 m-1 mt-3",attrs:{"variant":"outline","size":"lg","type":"button","color":"primary","shape":"square"},on:{"click":_vm.resetForm}},[_vm._v("Clear All")]),_c('CButton',{staticClass:"px-5 m-1 mt-3",attrs:{"type":"submit","size":"lg","color":"primary","shape":"square"}},[_vm._v("Submit")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }